import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | FAQ at Home Harmony Designs
			</title>
			<meta name={"description"} content={"Answers at Your Fingertips"} />
			<meta property={"og:title"} content={"FAQ | FAQ at Home Harmony Designs"} />
			<meta property={"og:description"} content={"Answers at Your Fingertips"} />
			<meta property={"og:image"} content={"https://tixelopride.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tixelopride.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tixelopride.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tixelopride.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tixelopride.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://tixelopride.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://tixelopride.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://tixelopride.com/img/2.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="760px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h1"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					FAQ
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Welcome to Our FAQ
				</Text>
				<Text font="--base">
				Seeking information about Home Harmony Designs? You've come to the right place. Here, we aim to provide answers to some of your most pressing questions, covering everything from our design process to how we handle your project's unique needs. If you don't find what you're looking for, don't hesitate to reach out to us for more detailed information.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				FAQ
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						What services do you offer?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Home Harmony Designs specializes in a wide range of interior design and renovation services, including full home renovations, kitchen and bathroom designs, living space refreshes, outdoor living spaces, sustainable design, and custom solutions.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						How do I start a project with you?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Begin by contacting us via phone or email. We'll schedule a consultation to discuss your vision, preferences, and the specifics of your space, followed by a proposal tailored to your project.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Can I see examples of your past work?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Absolutely. During our initial consultation, we can provide a portfolio of our completed projects, which showcase a variety of styles and solutions.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						What is your design process?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Our design process is collaborative and client-focused, starting with a consultation, followed by concept development, design approval, execution, and final walkthrough.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						How long does a project typically take?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						The timeline varies based on the project's scope and complexity. We strive to complete projects efficiently while ensuring the highest quality.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Do you work with sustainable materials and practices?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Yes, sustainability is a cornerstone of our approach. We prioritize eco-friendly materials and practices to create healthy, energy-efficient spaces.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});